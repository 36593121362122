import { useSiteData } from 'hooks/useSiteData';

export const useAboutData = () => {
  const { studios } = useSiteData();
  const countriesList: string[] = [];

  studios.forEach((studio) => {
    if (countriesList.indexOf(studio.country) === -1) {
      countriesList.push(studio.country);
    }
  });

  return {
    countriesList: countriesList.sort(),
    studiosTotal: studios.length,
  };
};
