import React from 'react';

import { Footer } from 'components/Footer';
import { HeadSeo } from 'components/HeadSeo';

import { useAboutData } from 'hooks/useAboutData';
import { Grid } from 'components/Grid';
import { GridItem } from 'components/GridItem';

import * as styles from './about.module.scss';

const AboutPage: React.FC = () => {
  const { countriesList, studiosTotal } = useAboutData();

  return (
    <>
      <HeadSeo />
      <Grid>
        <GridItem colS="13" colM="13" colL="12">
          <h1 className={styles.title}>About</h1>
        </GridItem>
        <GridItem colS="13" colM="13" colL="24">
          <p className={styles.text}>
            Design Studios born from a private list of design studios shared
            among friends, and now we decide to publish it to everyone.
          </p>

          <p className={styles.text}>
            Over time we've added many Studios and received contributions from
            people that received the initial list. We keep looking for studios
            across the world. Especially the studios that are outside the big
            centers.
          </p>

          <p className={styles.text}>
            We want to be an index of the best Design Studios around the world
            in disciplines like brand identity, graphic design, digital design,
            product design, and others.
          </p>
        </GridItem>
        <GridItem colS="13" colM="13" colL="14">
          <picture className={styles.picture}>
            <source media="(max-width: 767px)" srcSet="/poster_mobile.jpg" />
            <source media="(min-width: 768px)" srcSet="/poster_tablet.jpg" />
            <img className={styles.image} src="/poster_tablet.jpg" />
          </picture>
        </GridItem>
        <GridItem colS="13" colM="13" colL="24">
          <p className={styles.text}>
            We have {studiosTotal} design studios from{' '}
            {countriesList.map((country, index) => {
              const isLastIndex = index === countriesList.length - 1;
              return (
                <React.Fragment key={country}>
                  {index !== 0 && ', '}
                  {isLastIndex && 'and '}
                  {country}
                </React.Fragment>
              );
            })}
          </p>

          <p className={styles.text}>
            Feel free to{' '}
            <a className={styles.link} href="mailto:contact@designstudios.cc">
              contact us
            </a>{' '}
            or{' '}
            <a
              className={styles.link}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfOZyRxqtPJUOevq0HtARZ9WdM3iQq0B6SptNGrs9zswWhqMw/viewform"
              target="_blank"
            >
              suggest a studio
            </a>
            .
          </p>
        </GridItem>
      </Grid>
      <Footer />
    </>
  );
};

export default AboutPage;
